import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/login/Login';
import Portal from './pages/Portal';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import SupabaseConnect from './config/supabaseConnect';
import './App.css';
import SignUp from './components/login/Signup';
import VerifyEmail from './components/login/VerifyEmail';
import PasswordReset from './components/login/PasswordReset';
import { AuthProvider, useAuth } from './context/AuthContext';
import ForgotPassword from './components/login/ForgotPassword';

// Define the App component
const App = () => {
  const { user, loading } = useAuth();

  useEffect(() => {
    console.log('App.js - user:', user);
    console.log('App.js - role:', user?.role);
  }, [user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <Header />
        <div className="App-main">
          <Routes>
            <Route
              path="/"
              element={
                user?.role === 'user' ? 
                <Navigate to="/portal" replace /> : 
                <Navigate to="/login" replace />
              }
            />
            <Route
              path="/index"
              element={
                user?.role === 'user' ? 
                <Navigate to="/portal" replace /> : 
                <Navigate to="/login" replace />
              }
            />
            <Route path="/login" element={<Login />} />
            <Route 
              path="/portal" 
              element={
                user?.role === 'user' ? 
                <Portal /> : 
                <Navigate to="/login" replace />
              }
            />
            <Route path="/db-connect" element={<SupabaseConnect />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/reset-password/:token" element={<PasswordReset />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

// Export the component
const WrappedApp = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default WrappedApp;
