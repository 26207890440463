// src/utils/auth.controller.js

import supabase from '../config/supabaseClient';
import bcrypt from 'bcryptjs';
import { jwtVerify } from 'jose';
import { generateTokenAndSetCookie } from './generateTokenAndSetCookie'; 
import {sendVerificationEmail, sendWelcomeEmail, sendPasswordResetEmail, sendPasswordResetSuccessEmail} from '../mailerSend/emails';


// Helper function to hash password before storing it
const hashPassword = async (password) => {
    const salt = await bcrypt.genSalt(10); 
    const hashedPassword = await bcrypt.hash(password, salt); 
    return hashedPassword;
};

// Function to add a new user to the users table
export const addUser = async ({ email, password, role, name, isVerified }) => {
    try {
        if (!email || !password || !role || !name) {
            throw new Error('Please provide all required fields: email, password, role, and name');
        }

        // Check if the user already exists
        const { data: existingUser, error: checkError } = await supabase
            .from('users')
            .select('email')
            .eq('email', email);

        if (checkError) {
            throw checkError;
        }

        if (existingUser.length > 0) {
            throw new Error('User with that email already exists');
        }

        // Hash the user's password before storing it
        const hashedPassword = await hashPassword(password);
        const verificationToken = Math.floor(100000 + Math.random() * 900000).toString();
        const verificationTokenExpiresAt = new Date(Date.now() + 3600000).toISOString(); // 1 hour from now in ISO 8601 format


        // Insert user data into the 'users' table
        const { data, error } = await supabase
            .from('users')
            .insert([{
                email: email,  
                password: hashedPassword,  
                role: role,  
                name: name,  
                isverified: isVerified,  
                resetpasswordtoken: null,
                resetpasswordexpiresat: null,
                verificationtoken: verificationToken,
                verificationtokenexpiresat: verificationTokenExpiresAt,
            }])
            .select();

        if (error) {
            throw error;
        }

        console.log("Inserted data:", data);


        if (data && data.length > 0) {
            const { id, email, role } = data[0];  

            generateTokenAndSetCookie({ id, email, role });

            await sendVerificationEmail(email, verificationToken); 
            return { data, message: 'User successfully added and token set in localStorage' };
        } else {
            throw new Error('Failed to create user: no data returned');
        }


    } catch (error) {
        console.error('Error adding user:', error.message);
        throw new Error(error.message); // Throw the error so it can be caught in the calling function
    }
};

// Function to verify a user's email address
export const verifyEmail = async (verificationCode) => {
    try {
        // Validate that the code is a 6-digit number
        if (!/^\d{6}$/.test(verificationCode)) {
            throw new Error('Please enter a valid 6-digit verification code.');
        }

        // Find the user by verification token
        const { data, error } = await supabase
            .from('users')
            .select('user_id, verificationtoken, verificationtokenexpiresat, email, name')
            .eq('verificationtoken', verificationCode)  // Check for the verification code
            .single();

        if (error || !data) {
            throw new Error('No user found with this verification token');
        }

        // Check if the token has expired
        const currentDate = new Date();
        const tokenExpiryDate = new Date(data.verificationtokenexpiresat);

        if (currentDate > tokenExpiryDate) {
            throw new Error('The verification token has expired');
        }

        // Update user verification status
        const { error: updateError } = await supabase
            .from('users')
            .update({
                isverified: true,  // Mark the user as verified
                verificationtoken: null,  // Remove the verification token
                verificationtokenexpiresat: null,  // Remove the expiration date
            })
            .eq('user_id', data.user_id);

        if (updateError) {
            throw new Error('Failed to update user verification status. Please try again later.');
        }

        // Send a welcome email after successful verification
        await sendWelcomeEmail(data.email, data.name); 

        return { message: 'Your email has been successfully verified! You can now log in.' };

    } catch (error) {
        console.error('Error during email verification:', error.message);
        throw new Error(error.message); // Propagate the error for further handling
    }
};

// Function to log in a user
export const login = async ({ email, password }) => {
    try {
        if (!email || !password) {
            throw new Error('Please provide both email and password');
        }

        // Find the user by email
        const { data, error } = await supabase
            .from('users')
            .select('user_id, email, password, role')
            .eq('email', email)
            .single();  // We expect only one user to match this query

        // Handle errors if any
        if (error || !data) {
            throw new Error('No user found with this email');
        }

        // Compare the hashed password
        const isMatch = await bcrypt.compare(password, data.password);

        if (!isMatch) {
            throw new Error('Invalid password');
        }

        // Generate a JWT token and set it in a cookie
        generateTokenAndSetCookie({ id: data.user_id, email, role: data.role });

        // Ensure that `data` has the structure we expect
        if (data && data.user_id) {
            // Set the last login timestamp
            const lastlogin = new Date().toISOString();

            // Update the user record with the new last login date
            const { error: updateError } = await supabase
                .from('users')
                .update({ lastlogin })  // Update the lastLogin field
                .eq('user_id', data.user_id);  // Match the user by ID

            if (updateError) {
                console.error('Error updating last login date:', updateError.message);
                throw new Error('Failed to update last login date');
            }
        } else {
            throw new Error('User data is invalid');
        }

        return { status: 200, message: 'Login successful' }; // Simpler success message

    } catch (error) {
        console.error('Error during login:', error.message);
        throw new Error(error.message); // Propagate the error for further handling
    }
};

// Function to log out a user
export const logout = () => {
    document.cookie = 'token=; Expires=Thu, 01 Jan 1970 00:00:00 GMT; Path=/; SameSite=Strict;';
    window.location.href = '/login'; // Redirects to login page
};

// Function Forgot Password
export const forgotPassword= async (email) => {
    try{
        if (!email) {
            throw new Error('Please provide an email');
        }

        // Find the user by email
        const { data, error } = await supabase
            .from('users')
            .select('user_id, email, name')
            .eq('email', email)
            .single();  

        // Handle errors if any
        if (error || !data) {
            throw new Error('No user found with this email');
        }

        const resetPasswordToken = window.crypto.getRandomValues(new Uint8Array(20)).join('');
        const resetPasswordExpiresAt = new Date(Date.now() + 3600000).toISOString(); // 1 hour from now in ISO 8601 format

        // Update user data with reset password token and expiration date
        const { error: updateError } = await supabase
            .from('users')
            .update({
                resetpasswordtoken: resetPasswordToken,
                resetpasswordexpiresat: resetPasswordExpiresAt,
            })
            .eq('user_id', data.user_id);

        if (updateError) {
            throw new Error('Failed to update user reset password token');
        }

        // Send the password reset email
        await sendPasswordResetEmail(data.email, data.name, resetPasswordToken);

        return { message: 'Password reset token sent to your email' };
    }catch (error) {
        console.error('Error during forgot password:', error.message);
        throw new Error(error.message); // Propagate the error for further handling
    }   
};

// Function to reset password
export const resetPassword = async (resetToken, newPassword) => {
    try {
        const token = resetToken;
        const password = newPassword;

        // Step 1: Find the user based on the reset token
        const { data: user, error } = await supabase
            .from('users')
            .select('user_id, email, resetpasswordexpiresat')
            .eq('resetpasswordtoken', token)
            .single();

        if (error || !user) {
            throw new Error('No user found with this reset token');
        }

        // Step 2: Check if the token has expired
        const currentTime = new Date().toISOString();
        if (user.resetpasswordexpiresat < currentTime) {
            throw new Error('Reset token has expired');
        }
        const hashedPassword = await hashPassword(password);
        // Step 3: Update the user's password
        const { error: updateError } = await supabase
            .from('users')
            .update({
                password: hashedPassword, // Ensure to hash the password before updating
                resetpasswordtoken: null, // Clear the reset token after successful reset
                resetpasswordexpiresat: null // Clear the expiration time
            })
            .eq('user_id', user.user_id);


        if (updateError) {
            throw new Error('Failed to update password');
        }
         await sendPasswordResetSuccessEmail(user.email, user.name);
         return { message: 'Password reset succesful email sent' };
    } catch (error) {
        console.error('Error during password reset:', error.message);
        throw new Error(error.message); // Propagate the error for further handling
    }
};


// Function to check if the user is authenticated
export const checkAuth = async () => {
  try {
    // Get the token from the cookie
    const cookies = document.cookie.split('; ');
    const tokenCookie = cookies.find(row => row.startsWith('token='));

    // Check if tokenCookie exists
    if (!tokenCookie) {
      throw new Error('No token found');
    }

    // Extract the actual token part (after 'token=')
    const jwtToken = tokenCookie.split('=')[1];

    // Decode and verify the JWT token using jose
    const secretKey = '451735';  // Make sure to replace with your actual secret key

    const { payload } = await jwtVerify(jwtToken, new TextEncoder().encode(secretKey));

    // Validate the payload for the expected fields (id, email, role)
    if (!payload || !payload.id || !payload.email || !payload.role) {
      throw new Error('Invalid token payload');
    }

    // Now we can validate the user data (role, user_id, etc.)
    const { id, email, role } = payload;

    // Check if the user has valid role, and id
    console.log("User ID:", id);
    console.log("User Email:", email);
    console.log("User Role:", role);

    return { user: { id, email, role }, message: 'Authenticated' };

  } catch (error) {
    console.error('Error during authentication check:', error.message);
    return { error: error.message };  // Return the error message to handle it properly in the component
  }
};
