import React from 'react';
import './Subscribe.css';

function Subscribe() {
  return (
    <div className="subscribe-container">
      <h1 className="subscribe-heading">Subscribe Now</h1>
      <div className="subscribe-buttons">
        <button className="subscribe-button">Purchase Bonds</button>
        <button className="subscribe-button">Purchase Equity</button>
      </div>
    </div>
  );
}

export default Subscribe;
