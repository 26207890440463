// HouseList.js
import React, { useState, useEffect } from 'react';
import supabase from '../../config/supabaseClient';
import HouseCard from './HouseCard';
import './House.css';
import Pagination from '../Pagination/Pagination';
import Filter from '../Filter/Filter';

function HouseList() {
  const [fetchError, setFetchError] = useState(null);
  const [houses, setHouses] = useState([]);
  const [filteredHouses, setFilteredHouses] = useState([]); // State for filtered houses
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);
  const [selectedCounty, setSelectedCounty] = useState(""); // State for selected county
  const [counties, setCounties] = useState([]); // State for list of unique counties

  useEffect(() => {
    const fetchHouses = async () => {
      try {
        const { data, error } = await supabase
          .from('auctions')
          .select('date, time, county, openingBid, propertyAddress, assessedValue');

        if (error) {
          setFetchError('Could not fetch the auctions');
          setHouses([]);
          console.log(error);
        }

        if (data) {
          setHouses(data);
          setFilteredHouses(data); 
          setFetchError(null);

          // Extract unique counties for the filter
          const uniqueCounties = [...new Set(data.map((house) => house.county))];
          setCounties(uniqueCounties);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchHouses();
  }, []);

  useEffect(() => {
    // Filter houses based on the selected county
    if (selectedCounty === "") {
      setFilteredHouses(houses); // Show all if no county is selected
    } else {
      const filtered = houses.filter((house) => house.county === selectedCounty);
      setFilteredHouses(filtered);
    }
  }, [selectedCounty, houses]); // Re-filter when county or houses change

  if (loading && houses.length === 0) {
    return <h2>Loading...</h2>;
  }

  // Get current houses for pagination
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentRecords = filteredHouses.slice(indexOfFirstPost, indexOfLastPost);
  const howManyPages = Math.ceil(filteredHouses.length / postsPerPage);

  return (
    <div className="gridContainer">
      <div className="housesList">
        {fetchError && <p className="text-red-500">{fetchError}</p>}

        <Filter
          counties={counties}
          selectedCounty={selectedCounty}
          onChange={setSelectedCounty}
        />

        {filteredHouses.length > 0 ? (
          <div className="house-grid">
            {currentRecords.map((house) => (
              <HouseCard key={house.id} house={house} />
            ))}
          </div>
        ) : (
          !fetchError && <p>No auctions available</p>
        )}
      </div>
      <div className="pagination-container">
        <Pagination pages={howManyPages} setCurrentPage={setCurrentPage} />
      </div>
    </div>
  );
}

export default HouseList;
