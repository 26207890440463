import React from 'react';
import './AboutUs.css'; // Import the CSS file

function AboutUs() {
  return (
    <div className="about-container">
      <h4 className="about-heading">
        Propertunity LTD
      </h4>
      <p className="about-text">
        Propertunity is a private money lending fund which helps make investments into distressed properties. We have a combined 70 years of experience in various areas of real estate, investments, and wealth creation. More stuff to put in here as lorem ipsum...
      </p>
    </div>
  );
}

export default AboutUs;
