import React, { useState } from 'react';
import { resetPassword } from '../../utils/auth.controller'; 
import './Login.css'; 



function PasswordReset() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");


  // Handle password reset logic
  const handlePasswordReset = async (e) => {
    e.preventDefault();

    // Clear any previous error or success message
    setError("");
    setSuccessMessage("");

    // Check if the passwords match
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    // Extract the token from the URL path
    const path = window.location.pathname;
    const token = path.split('/').pop().slice(6); // Assuming token is part of the URL path after /reset/

    if (!token) {
      setError("Invalid reset token.");
      return;
    }

    try {
      // Call the resetPassword function with token and new password
      await resetPassword(token, newPassword);
      setTimeout(() => {
        window.location.href = '/login'; 
    }, 10);
    } catch (error) {
      setError(error.message); // Display any error that occurs
    }
  };

  return (
    <div className="login-page">
      <div className="login-image"></div> {/* You can add a background image if needed */}

      {/* Right Side Form */}
      <div className="login-form-container">
        <div className="login-form">
          <h2>Reset Your Password</h2>

          {/* Show error message if there's any */}
          {error && <p className="error-message">{error}</p>}

          {/* Show success message if password reset is successful */}
          {successMessage && <p className="success-message">{successMessage}</p>}

          <form onSubmit={handlePasswordReset}>
            <div className="form-group">
            <div className="input-wrapper">
            <i className="fa fa-lock"></i>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Password"
                required
              />
            </div>
            </div>

            <div className="form-group">
            <div className="input-wrapper">
            <i className="fa fa-lock"></i>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder=" Confirm Password"
                required
              />
            </div>
            </div>

            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PasswordReset;
