import React, { useState } from "react";
import logoImage from '../../assets/Slide8.png';
import { forgotPassword } from '../../utils/auth.controller';
import './Login.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loading state for forgot password process

  // Forgot Password handler
  const handleForgotPassword = async (e) => {
    e.preventDefault(); // Prevent form from submitting by default
    if (!email) {
      setErrorMessage("Please provide an email address.");
      return;
    }
    setIsLoading(true); // Set loading to true when starting the request

    try {
      await forgotPassword(email); // Call the forgotPassword function
      setErrorMessage(""); // Clear any previous error messages
      alert("If the email is registered, we have sent a reset link to it.");
    } catch (error) {
      setErrorMessage("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false); // Stop the loading when done
    }
  };

  return (
    <div className="login-page">
      <div
        className="login-image"
        style={{ backgroundImage: `url(${logoImage})` }} // Using the imported image
      ></div>

      {/* Right Side Form */}
      <div className="login-form-container">
        <div className="login-form">
          <h2>Forgot Password</h2>
          <form onSubmit={handleForgotPassword}>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter your email"
              />
            </div>
            
            {/* Display the error message if it exists */}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            
            {/* Submit Button */}
            <button type="submit" disabled={isLoading}>
              {isLoading ? "Sending..." : "Send Reset Link"}
            </button>
          </form>

          {/* Forgot Password Link (not used in form anymore) */}
          <div className="forgot-password-container">
            <p>Remembered your password? <a href="/login">Login here</a></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
