import { SignJWT } from 'jose';

export const generateTokenAndSetCookie = async (user) => {
    try {
        // Create a JWT token with the user's id, email, and role
        const token = await new SignJWT({
            id: user.id,
            email: user.email,
            role: user.role,
        })
        .setExpirationTime('30d') // Set token expiration to 30 days
        .setProtectedHeader({ alg: 'HS256' }) 
        .sign(new TextEncoder().encode('451735')); // Replace 'your-secret-key' with your actual secret key

        // Cookie settings for HttpOnly, secure, and expiration
        const maxAge = 30 * 24 * 60 * 60 * 1000; // 30 days
        const cookieOptions = `SameSite=Strict; Max-Age=${maxAge}; Path=/`;  // No HttpOnly here to allow access in JS

        // Set the cookie in the browser (JWT in cookie)
        document.cookie = `token=${token}; ${cookieOptions}`;

        console.log('Token generated and cookie set:', token);
        return token; 
    } catch (error) {
        console.error('Error generating token or setting cookie:', error);
        throw new Error('Failed to generate token or set cookie');
    }
};
