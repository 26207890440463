import React from 'react';
import './TabInvestments.css';


import Plaid from './Plaid';

function TabInvestments() {
  return (
    <>
    <div>

        <Plaid />
        </div>


    </>
  )
}

export default TabInvestments

