import React, { useState } from 'react';
import { addUser } from '../../utils/auth.controller'; 
import './Login.css'; 

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // Store error messages
    const [successMessage, setSuccessMessage] = useState(''); // Store success messages

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Pass the form data to the addUser function
            const newUser = await addUser({
                email,
                password,
                role: 'user', // Assuming default role
                name,
                isVerified: false, // Assuming default value
            });

            // Success handling
            console.log('User added successfully:', newUser);
            setSuccessMessage('User added successfully!'); 
            setErrorMessage(''); 
            
            setTimeout(() => {
                window.location.href = '/verify-email'; 
            }, 10);

        } catch (error) {
            console.error('Error during sign-up:', error.message);
            setErrorMessage(error.message); 
            setSuccessMessage('');
        }
    };

    return (
        <div className="login-page">
            <div className="login-image"></div> 
            <div className="login-form-container">
                <div className="login-form">
                    <h2>Sign Up</h2>

                    {/* Display success or error message */}
                    {successMessage && <p className="success-message">{successMessage}</p>}
                    {errorMessage && <p className="error-message">{errorMessage}</p>}

                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                        <div className="input-wrapper">
                        <i className="fa fa-envelope"></i>
                            <input 
                                type="email" 
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)} 
                                placeholder="Email"
                                required
                            />
                        </div>
                        </div>
                        <div className="form-group">
                        <div className="input-wrapper">
                        <i className="fa fa-lock"></i>
                            <input 
                                type="password" 
                                value={password} 
                                onChange={(e) => setPassword(e.target.value)} 
                                placeholder="Password"
                                required 
                            />
                        </div>
                        </div>
                        <div className="form-group">
                        <div className="input-wrapper">
                        <i className="fa fa-user"></i>
                            <input 
                                type="text" 
                                value={name} 
                                onChange={(e) => setName(e.target.value)} 
                                placeholder="Name"
                                required 
                            />
                        </div>
                        </div>
                        <button type="submit">Sign Up</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
