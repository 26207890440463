import React, { useState, useEffect } from "react";
import { login } from '../../utils/auth.controller';
import './Login.css';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // Define successMessage state

  // Clear input fields on component mount
  useEffect(() => {
    setEmail("");
    setPassword("");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Clear any error messages
    setSuccessMessage(""); // Clear any success messages

    try {
        // Perform the login operation
        await login({ email, password });

        // Set success message
        setSuccessMessage("Login successful! Redirecting...");

        // Delay the navigation to allow success message to display
        setTimeout(() => {
            window.location.href = '/portal';  // Force a page reload and navigate to the portal
        }, 1000); // Wait for 1 second before redirecting

    } catch (err) {
        // Log and display error
        console.error("Error during login:", err);
        setErrorMessage("Something went wrong. Please try again later.");
    }
};


  const handleForgotPassword = () => {
    setTimeout(() => {
      window.location.href = '/forgot-password'; 
  }, 10);
  };

  return (
    <div className="login-page">
      <div className="login-form-container">
        <div className="login-form">
          <h2>Login</h2>
          
          {successMessage && <p className="success-message">{successMessage}</p>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="input-wrapper">
                <i className="fa fa-envelope"></i>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  autoComplete="new-email" 
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <div className="input-wrapper">
                <i className="fa fa-lock"></i>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  autoComplete="new-password"
                  required
                />
              </div>
            </div>
            
            <button type="submit">Login</button>
          </form>

          <div className="forgot-password-container">
            <button
              type="button"
              className="forgot-password-link"
              onClick={handleForgotPassword}
            >
              Forgot Password?
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;