import React from 'react';
import './Filter.css';  // Make sure this is imported

const Filter = ({ counties, selectedCounty, onChange }) => {
  return (
    <div className="filter-container">
      <select
        id="county"
        value={selectedCounty}
        onChange={(e) => onChange(e.target.value)}
        className="filter-select"
      >
        <option value="">All Counties </option>
        {counties.map((county) => (
          <option key={county} value={county}>
            {county}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Filter;
