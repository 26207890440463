import React, { useEffect, useState } from 'react'
import supabase from './supabaseClient'

function SupabaseConnect() {
    const [status, setStatus] = useState('Testing connection...')
    const [users, setUsers] = useState([])

    useEffect(() => {
        const testConnection = async () => {
            try {
                // Fetch all rows from the 'users' table
                const { data, error } = await supabase
                    .from('users')
                    .select('email, password_hash')

                // Log the entire response from Supabase
                console.log("Fetched data:", data);
                if (error) {
                    throw error
                }

                // Set users state with the fetched data
                setUsers(data)

                // Update status on successful fetch
                setStatus('Connection successful! Data fetched.')
            } catch (error) {
                setStatus(`Connection error: ${error.message}`)
                console.log("Error details:", error)
            }
        }

        testConnection()
    }, [])

    return (
        <div style={{ padding: '20px' }}>
            <h2>Supabase Connection Test</h2>
            <p>{status}</p>
            
            {/* Display all users data as raw output */}
            <pre>{JSON.stringify(users, null, 2)}</pre>
        </div>
    )
}

export default SupabaseConnect
