import { PASSWORD_RESET_REQUEST_TEMPLATE, PASSWORD_RESET_SUCCESS_TEMPLATE, VERIFICATION_EMAIL_TEMPLATE, WELCOME_EMAIL_TEMPLATE } from './emailTemplates';

export const sendVerificationEmail = async (email, verificationToken) => {
    // Create the HTML content using the provided verification token
    const htmlContent = VERIFICATION_EMAIL_TEMPLATE.replace('{verificationCode}', verificationToken);
  
    // Prepare the payload to match the worker's expected structure
    const emailPayload = {
      fromEmail: "noreply@portal.propertunityltd.com", // Sender email
      fromName: "Propertunity Support", // Sender name
      toEmail: email, // Recipient email (instead of 'to' array)
      subject: "Verify your email", // Subject of the email
      message: `Please use the following code to verify your email: ${verificationToken}`, // Text version of the message
      htmlContent: htmlContent, // Using HTML content with the verification code
    };
  
    console.log("Email Payload:", emailPayload); // Debug: Log the payload to verify the structure
  
    try {
      // Send the HTTP request to the worker endpoint
      const response = await fetch('https://sendemail.iriscondominiums.workers.dev', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(emailPayload),
      });
  
      // Check if the response is successful (2xx status code)
      if (!response.ok) {
        console.error("Error sending email. HTTP Status:", response.status);
        const errorText = await response.text();  // Capture any error message returned by the worker
        console.error("Error Response Body:", errorText);  // Log the error body (if any)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      // Check for success based on status code (e.g., 200 OK)
      if (response.status === 200) {
        console.log("Email sent successfully.");
      } else {
        console.warn("Email sent with a non-200 status:", response.status);
      }
  
    } catch (error) {
      console.error("Error:", error);
      throw new Error("Error sending email");
    }
  };
  

  export const sendWelcomeEmail = async (email, name) => {
    // Create the HTML content using the provided verification token
    let htmlContent = WELCOME_EMAIL_TEMPLATE.replace('{name}', name); 
    htmlContent = htmlContent.replace('{email}', email);
  
    // Prepare the payload to match the worker's expected structure
    const emailPayload = {
      fromEmail: "noreply@portal.propertunityltd.com", // Sender email
      fromName: "Propertunity Support", // Sender name
      toEmail: email, // Recipient email (instead of 'to' array)
      subject: "Welcome to Propertunity", // Subject of the email
      message: `Welcome ${name}, your username is ${email}. We are excited to meet your investment goals.`, // Text version of the message
      htmlContent: htmlContent, // Using HTML content with the verification code
    };
  
    console.log("Email Payload:", emailPayload); // Debug: Log the payload to verify the structure
  
    try {
      // Send the HTTP request to the worker endpoint
      const response = await fetch('https://sendemail.iriscondominiums.workers.dev', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(emailPayload),
      });
  
      // Check if the response is successful (2xx status code)
      if (!response.ok) {
        console.error("Error sending email. HTTP Status:", response.status);
        const errorText = await response.text();  // Capture any error message returned by the worker
        console.error("Error Response Body:", errorText);  // Log the error body (if any)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      // Check for success based on status code (e.g., 200 OK)
      if (response.status === 200) {
        console.log("Email sent successfully.");
      } else {
        console.warn("Email sent with a non-200 status:", response.status);
      }
  
    } catch (error) {
      console.error("Error:", error);
      throw new Error("Error sending email");
    }
  };

  export const sendPasswordResetEmail = async (email, name, resetToken) => {
    try {
      // Check if the resetToken is correctly passed
      console.log('Reset Token:', resetToken); // Debugging line: Ensure it's the correct token
  
      // Create the reset URL with the token
      const resetURL = "https://portal.propertunityltd.com/reset-password/token=" + resetToken;
      console.log('Generated Reset URL:', resetURL); // Debugging line: Verify the URL
  
      // Ensure the template contains {resetURL} and replace it correctly
      const htmlContent = PASSWORD_RESET_REQUEST_TEMPLATE.replace('{resetURL}', resetURL);
      console.log('Generated HTML Content:', htmlContent); // Debugging line: Verify the generated HTML
  
      // Prepare the email payload
      const emailPayload = {
        fromEmail: "noreply@portal.propertunityltd.com", // Sender email
        fromName: "Propertunity Support", // Sender name
        toEmail: email, // Recipient email
        subject: "Reset Your Password", // Subject of the email
        message: `Please use the following link to reset your password: ${resetToken}`, // Plain text version
        htmlContent: htmlContent, // HTML version with the reset URL
      };
  
      console.log("Email Payload:", emailPayload); // Debugging line: Log the email payload to verify its structure
  
      // Send the email using the worker endpoint
      const response = await fetch('https://sendemail.iriscondominiums.workers.dev', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(emailPayload),
      });
  
      // Check if the response is successful (2xx status code)
      if (!response.ok) {
        console.error("Error sending email. HTTP Status:", response.status);
        const errorText = await response.text();  // Capture any error message returned by the worker
        console.error("Error Response Body:", errorText);  // Log the error body (if any)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      // Check for success based on status code (e.g., 200 OK)
      if (response.status === 200) {
        console.log("Email sent successfully.");
      } else {
        console.warn("Email sent with a non-200 status:", response.status);
      }
  
    } catch (error) {
      console.error("Error:", error);
      throw new Error("Error sending email");
    }
  };

  export const sendPasswordResetSuccessEmail = async (email, name) => {
    try {
      // Prepare the email payload
      const emailPayload = {
        fromEmail: "noreply@portal.propertunityltd.com", // Sender email
        fromName: "Propertunity Support", // Sender name
        toEmail: email, // Recipient email (instead of 'to' array)
        subject: "Your Password Has Been Changed", // Subject of the email
        message: `Hello ${name}, your password has been succesfully changed.`, // Text version of the message
        htmlContent: PASSWORD_RESET_SUCCESS_TEMPLATE, // Using HTML content with the verification code
      }
      console.log("Email Payload:", emailPayload); // Debugging line: Log the email payload to verify its structure
  
      // Send the email using the worker endpoint
      const response = await fetch('https://sendemail.iriscondominiums.workers.dev', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(emailPayload),
      });
  
      // Check if the response is successful (2xx status code)
      if (!response.ok) {
        console.error("Error sending email. HTTP Status:", response.status);
        const errorText = await response.text();  // Capture any error message returned by the worker
        console.error("Error Response Body:", errorText);  // Log the error body (if any)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      // Check for success based on status code (e.g., 200 OK)
      if (response.status === 200) {
        console.log("Email sent successfully.");
      } else {
        console.warn("Email sent with a non-200 status:", response.status);
      }
  
    } catch (error) {
      console.error("Error:", error);
      throw new Error("Error sending email");
    }
  }