import React, { useEffect, useState } from 'react';
import { PlaidLink } from 'react-plaid-link';  // Import the PlaidLink component

const PlaidSuccess = () => {
  
  const [token, setToken] = useState(null);  // State to hold the link_token
  const [error, setError] = useState(null);  // To track errors

  // Fetch token asynchronously when the component mounts
  useEffect(() => {
    const getToken = async () => {
      try {
        const resp = await fetch('/plaid');
        
        if (!resp.ok) {
          throw new Error(`Error: ${resp.statusText}`);
        }

        const postresp = await resp.json();
        console.log('Received data:', postresp);  // Log to ensure the correct response
        setToken(postresp.link_token);  // Use link_token from the response
      } catch (err) {
        setError(`Failed to load data: ${err.message}`);
      }
    };

    // Call the async function inside useEffect
    getToken();
  }, []);  // Empty dependency array means it only runs once on mount

  // onSuccess function that will be triggered when the user successfully links their bank account
  const onSuccess = (token, metadata) => {
    console.log('Plaid Link Success!');
    console.log('Received public token:', token);  // Log the received public_token
    console.log('Metadata:', metadata);  // Log any metadata returned
  };

  // onExit function to handle when the user exits the Plaid Link flow
  const onExit = (err, metadata) => {
    console.log('User exited Plaid Link flow');
    console.error('Error (if any):', err);
    console.log('Metadata:', metadata);
  };

  // If there's an error or token is still loading, show a message
  if (error) {
    return <div>{error}</div>;
  }

  if (!token) {
    return <div>Loading...</div>;  // Show loading until the token is available
  }

  return (
    <div>
      <h1>Test Plaid Link</h1>

      {/* Render PlaidLink once the token is available */}
      <PlaidLink
        token={token}  
        clientName="Propertunity LTD"  
        env="sandbox"  
        product={['auth']}  
        onSuccess={onSuccess}  
        onExit={onExit}  // Pass the onExit callback
      >
        Link your bank account
      </PlaidLink>
    </div>
  );
};

export default PlaidSuccess;
