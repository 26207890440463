
// AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { checkAuth } from '../utils/auth.controller';

const AuthContext = createContext(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    const authenticateUser = async () => {
      try {
        const result = await checkAuth();
        if (mounted) {
          setUser(result.user || null);
        }
      } catch (error) {
        console.error('Authentication check failed:', error);
        if (mounted) {
          setUser(null);
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    authenticateUser();
    return () => {
      mounted = false;
    };
  }, []);

  const value = {
    user,
    loading,
    setUser
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};